import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const BackButton = props => {
  const data = useStaticQuery(graphql`
    query {
      arrow: file(relativePath: { eq: "arrow-right.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <ButtonLink className={props.className} to={props.to}>
      <Arrow url={data.arrow.publicURL} />
      <div>{props.label || 'Back'}</div>
    </ButtonLink>
  );
};

export default BackButton;

const ButtonLink = styled(Link)`
  display: inline-block;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
  font-size: 20px;
  display: flex;
  align-items: center;
  :hover,
  :focus {
    opacity: 0.9;
  }
  :active {
    opacity: 0.85;
  }
`;

const Arrow = styled.div`
  background: url('${p => p.url}');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -0.1em;
`;
