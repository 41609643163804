import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import Wrap from '../components/Wrap';
import H1 from '../components/H1';
import Markdown from '../components/Markdown';
import BackButton from '../components/BackButton';
import Progress from '../components/Progress';
import Updates from '../components/Updates';
import Challenges from '../components/Challenges';
import SecondaryButtonAligned from '../components/SecondaryButtonAligned';
import CTAButton from '../components/CTAButton';
import Prompt from '../components/Prompt';
import XArea from '../components/XArea';
import useAuth from '../hooks/useAuth';

const ChallengeDetailPage = props => {
  const [isAuth, setAuth] = useAuth();
  const [updatesLimit, setUpdatesLimit] = React.useState(2);
  const updatesLimited = React.useMemo(
    () => ({
      ...props.data.updates,
      nodes: props.data.updates.nodes.slice(0, updatesLimit),
    }),
    [props.data.updates, updatesLimit]
  );

  return (
    <Layout>
      <SEO title="Challenges" />
      {isAuth ? (
        <>
          <XHero>
            <Wrap>
              <XImage image={props.data.xImage.publicURL} />
              <BackButton to="/challenges/" />
              <H1 css={{ textAlign: 'left' }}>{props.data.challenge.title}</H1>
              <Markdown
                css={{ marginBottom: 0 }}
                dangerouslySetInnerHTML={{
                  __html: props.data.challenge.intro.md.html,
                }}
              />
            </Wrap>
          </XHero>
          <Wrap>
            <ShiftedH1>{props.data.page.aboutTitle}</ShiftedH1>
            <Markdown
              css={{ marginBottom: 0 }}
              dangerouslySetInnerHTML={{
                __html: props.data.challenge.about.md.html,
              }}
            />
            <ButtonSpacer>
              <CTAButton href="/join/">{props.data.page.joinButton}</CTAButton>
            </ButtonSpacer>
            <H1 css={{ marginTop: '3em' }}>{props.data.page.updatesTitle}</H1>
            <Progress />
            <Updates updates={updatesLimited} />
            {updatesLimited.totalCount > updatesLimit && (
              <SecondaryButtonAligned
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setUpdatesLimit(updatesLimit + 4);
                }}
              >
                {props.data.page.loadOlderButton}
              </SecondaryButtonAligned>
            )}
          </Wrap>
          <XArea css={{ marginTop: '6em' }}>
            <Wrap>
              <Avatars>
                <AvatarsInner>
                  {props.data.challenge.avatars.map((avatar, index) => {
                    return (
                      <Avatar key={index}>
                        <Img alt={avatar.title} fixed={avatar.fixed} />
                      </Avatar>
                    );
                  })}
                </AvatarsInner>
              </Avatars>
              <Markdown
                css={{ marginBottom: 0 }}
                dangerouslySetInnerHTML={{
                  __html: props.data.challenge.aboutEntrepreneurs.md.html,
                }}
              />
              <ButtonSpacer>
                <CTAButton href="/join/">
                  {props.data.page.joinButton}
                </CTAButton>
              </ButtonSpacer>
            </Wrap>
          </XArea>
          <Wrap>
            <H1>{props.data.page.otherTitle}</H1>
            <Challenges challenges={props.data.challenges} />
          </Wrap>
        </>
      ) : (
        <Prompt setAuth={setAuth} />
      )}
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    challenge: contentfulChallenge(id: { eq: $id }) {
      title
      intro {
        md: childMarkdownRemark {
          html
        }
      }
      about {
        md: childMarkdownRemark {
          html
        }
      }
      aboutEntrepreneurs {
        md: childMarkdownRemark {
          html
        }
      }
      slug
      avatars {
        title
        fixed(width: 70, quality: 100) {
          ...GatsbyContentfulFixed
        }
      }
    }
    page: contentfulChallengeDetailPage {
      updatesTitle
      otherTitle
      aboutTitle
      loadOlderButton
      joinButton
    }
    xImage: file(relativePath: { eq: "x-background.svg" }) {
      publicURL
    }
    challenges: allContentfulChallenge(
      sort: { fields: createdAt, order: DESC }
      filter: { id: { ne: $id } }
    ) {
      nodes {
        ...ContentfulChallengeListView
      }
    }
    updates: allContentfulUpdate(
      sort: { order: DESC, fields: createdAt }
      filter: { challenge: { id: { eq: $id } } }
    ) {
      totalCount
      nodes {
        ...ContentfulUpdateListView
      }
    }
  }
`;

export default ChallengeDetailPage;

const XHero = styled.div`
  background: white
    linear-gradient(
      90deg,
      rgba(217, 235, 243, 1) 0%,
      rgba(217, 235, 243, 0.13) 50%
    );
  position: relative;

  padding: 30px 0 30px;
  ${p => p.theme.m} {
    padding: 40px 0 40px;
  }
  ${p => p.theme.l} {
    padding: 40px 0 140px;
    :after {
      position: absolute;
      content: '';
      bottom: 0;
      height: 80px;
      width: calc(100% - 120px);
      max-width: 900px;
      left: 50%;
      transform: translateX(-50%);
      background: white;
    }
  }
`;

const ShiftedH1 = styled(H1)`
  ${p => p.theme.l} {
    margin-top: -20px;
    position: relative;
  }
`;

const XImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: url('${p => p.image}');
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  ~ * {
    position: relative;
  }
`;

const Avatars = styled.div`
  margin: 30px 0 30px;
  ${p => p.theme.m} {
    margin: 40px 0 40px;
  }
  ${p => p.theme.l} {
    margin: 60px 0 60px;
  }
`;

const AvatarsInner = styled.div`
  margin: -10px;
  display: flex;
  flex-wrap: wrap;
`;

const Avatar = styled.div`
  width: 70px;
  height: 70px;
  flex: none;
  margin: 10px;
  border-radius: 50%;
  background: red;
  overflow: hidden;
`;

const ButtonSpacer = styled.div`
  text-align: center;
  margin: 60px 0;
`;
